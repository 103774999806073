body {
  font-family: "Courier New", Courier, monospace;
  background-color: #8e5f5f;
  color: #9f9fc2;
}

input {
  background-color: #ebaaa9;
  color: inherit;
  border: #ebaaa9;
  border-radius: 3px;
}

a {
  color: inherit;
}

input[type="text"] {
  font-size: inherit;
  font-family: inherit;
}

[data-reach-slider-range] {
  background: #ebaaa9;
}

[data-reach-slider-track] {
  background: #d9a1a0;
}

[data-reach-slider-handle] {
  background: #9f9fc2;
}

h4 {
  padding-left: 3vw;
  padding-top: 1vh;
}
